.react-confirm-alert-overlay{
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5) none repeat scroll 0% 0%;
    display: flex;
    z-index: 999;
    justify-content: center;
    align-items: center;
}

#react-confirm-alert-firm-svg{
    display: none;
}
