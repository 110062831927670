@charset "UTF-8";

@font-face {
    font-family: "times-new-roman";
    src:url("fonts/times.ttf");
    src:url("fonts/times.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "times-new-roman";
    src:url("fonts/timesbd.ttf");
    src:url("fonts/timesbd.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "times-new-roman";
    src:url("fonts/timesbi.ttf");
    src:url("fonts/timesbi.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "times-new-roman";
    src:url("fonts/timesi.ttf");
    src:url("fonts/timesi.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}
